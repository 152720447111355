.partners #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .partners #num-section-1 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.partners #num-section-1 {}

.partners #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .partners #num-section-2 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.partners #num-section-2 {}

.partners #num-section-3 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .partners #num-section-3 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.partners #num-section-3 {}

.partners #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .partners #num-section-4 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.partners #num-section-4 {}

.partners #num-section-5 {
    background-color: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .partners #num-section-5 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.partners #num-section-5 {}

.partners #num-section-6 {
    background-image: url(https://toku.co/wp-content/uploads/2022/11/cta_partners_mobile-1.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .partners #num-section-6 {
        background-image: url(https://toku.co/wp-content/uploads/2022/11/cta_partners.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.partners #num-section-6 {}