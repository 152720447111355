

.slider{
  
  padding: 3px 10px;
}
.slide-item {
  display: inline-block;
  animation: slide-left 20s linear infinite;
}

@keyframes slide-left {
  0%{
      transform: translate(100%);
  }


  /* 50% {
    transform: translate(-50%);
  } */
  
  100% {
      transform: translateX(-150%);
    }
   
    /* 50%{
      transform: translateX(-10%);
    } */
    /* 100%{
      transform: translateX(-0%);
    } */
    /* 50% {
      transform: translateX(10%);
    } */
   
    /* 90% {
      transform: translateX(-90%);
    }
    100%{
      transform: translate(-100%);
    } */
}
.slide-item a{
  color: black;
  opacity: 0.7;
  padding-right: 50px;
}
.slide-item a:hover{
  text-decoration: none;
}


.home #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-3 {
  background-color: #7dbaab;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-3 {
  background-color: #7dbaab;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-3 .side-two {
  text-align: center;
}

.home #num-section-4 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-6 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-6 .img-class {
  max-width: 600px;
  display: block;
}

.home #num-section-7 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-9 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .home #num-section-9::before {
    /* background-image: url(/wp-content/uploads/2022/12/right-mic.png); */
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 500px;
    background-position: center right;
    z-index: +1;
  }
}

.home #num-section-10 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-10 img {
  max-width: 700px;
  display: block;
}

@media screen and (max-width: 768px) {
  .home #num-section-10 img {
    max-width: 100%;
  }
}

.home #num-section-11 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .home #num-section-11::after {
    /* background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46311-3.png); */
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 800px;
    z-index: +1;
  }
}

.home #num-section-12 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-13 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-13 * {
  z-index: 2;
}

.home #num-section-14 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-15 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-15 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-16 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-16 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-16 img {
  max-width: 600px;
  display: block;
}

.home #num-section-17 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-17 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-18 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-18 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-19 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-19 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-19 .container {
  background: white;
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  max-width: 90%;
}

.home #num-section-20 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-20 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-21 {
  background-color: #5fab98;
  /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46376.png); */
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .home #num-section-21 {
    background-color: #5fab98;
    /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46375.png); */
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.home #num-section-21 .image-one img {
  top: 20px;
  position: relative;
}

.home #num-section-21 .image-two img {
  top: -20px;
  position: relative;
}
