.government-solution #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-4 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-5 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-6 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-6 {
    background-position: #f1f5fe;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-7 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-9 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .government-solution #card-id-37-1 .card {
    background-image: url("https://toku.co/wp-content/uploads/2022/11/logo-microsoft-team.png");
  }
}

@media (min-width: 768px) {
  .government-solution #card-id-37-2 .card {
    background-image: url("https://toku.co/wp-content/uploads/2022/11/logo-zoom.png");
  }
}

.government-solution #num-section-10 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-11 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-12 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-13 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-14 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-14 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-15 {
  background-color: #5fab98;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .government-solution #num-section-15 {
    background-color: #5fab98;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.government-solution #num-section-15 img {
  max-width: 90%;
  margin: 0 auto;
}
