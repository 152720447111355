

.header-hero{
  background-color: #7DBAAB;
}

.hero-contact_center {
  background-image: url('../../assets/images/firstsection-background1.svg');
}
@media ( min-width: 768px ) {
  .hero-contact_center {
    background-image: url('../../assets/images/firstsection-background2.svg');
  }
}