.campaign-manager #num-section-1 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-1 {
}

.campaign-manager #num-section-2 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-2 {
}

.campaign-manager #num-section-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-3 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-3 {
}

.campaign-manager #num-section-4 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .campaign-manager #num-section-4::after {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46311-3.png);
    background-size: initial;
    background-repeat: no-repeat;
    background-position: bottom left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 800px;
    z-index: -1;
  }

  .campaign-manager #num-section-4::before {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/right-mic.png);
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 400px;
    background-position: center right;
    z-index: -1;
  }
}

.campaign-manager #num-section-5 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-5 {
}

.campaign-manager #num-section-6 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-6 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-6 {
}

.campaign-manager #num-section-7 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-7 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-7 {
}

.campaign-manager #num-section-8 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-8 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-8 .container {
  background: white;
  box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  max-width: 90%;
}

.campaign-manager #num-section-9 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-9 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-9 {
}

.campaign-manager #num-section-10 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-10 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-10 {
}

.campaign-manager #num-section-11 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-11 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-11 {
}

.campaign-manager #num-section-12 {
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-12 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .campaign-manager #num-section-12::before {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46344.png);
    background-size: initial;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 500px;
    height: 500px;
    background-position: top right;
    z-index: -1;
  }
}

@media (min-width: 768px) {
  .campaign-manager #num-section-12::after {
    background-image: url(https://toku.co/wp-content/uploads/2022/12/Frame-46345.png);
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center left;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 500px;
    height: 500px;
    z-index: -1;
  }
}

.campaign-manager #num-section-13 {
  background-color: #f1f5fe;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-13 {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-13 {
}

.campaign-manager #num-section-14 {
  background-color: #5fab98;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 768px) {
  .campaign-manager #num-section-14 {
    background-color: #5fab98;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.campaign-manager #num-section-14 .img-class {
  max-width: 300px;
  display: block;
  margin: 0 auto 20px auto;
}
