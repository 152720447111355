.press #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .press #num-section-1 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.press #num-section-2 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .press #num-section-2 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.press #num-section-2 .card-normal {
    min-height: auto;
}

.press #num-section-3 {
    background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46301-1.png);
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .press #num-section-3 {
        background-image: url(https://toku.co/wp-content/uploads/2023/01/Frame-46396.png);
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.press #num-section-3 .row {
    padding-bottom: 0 !important;
}

.press #num-section-4 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .press #num-section-4 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.press #num-section-5 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .press #num-section-5 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.press #num-section-6 {
    /* background-image: url(https://toku.co/wp-content/uploads/2023/01/CTA.png); */
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .press #num-section-6 {
        /* background-image: url(https://toku.co/wp-content/uploads/2023/01/Group-1000005176.png); */
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.press #num-section-6 .image-one img {
    top: 20px;
    position: relative;
}

.press #num-section-6 .image-two img {
    top: -20px;
    position: relative;
}