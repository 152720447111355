.contact-us .hero-contact_us {
    background-image: url('../../assets/images/firstsection-background1.svg');
}

@media (min-width: 768px) {
    .contact-us .hero-contact_us {
        background-image: url('../../assets/images/firstsection-background1.svg');
    }
}

.contact-us #num-section-1 {
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .contact-us #num-section-1 {
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.contact-us #num-section-1 {
    margin-top: -50px;
}

@media (min-width: 992px) {
    .contact-us #num-section-1 {
        margin-top: -240px;
    }
}